<template>
	<div class="container" ref="mainContainer">
		<v-row>
			<v-col cols="12">
				<v-btn
					class="rounded-pill my-2"
					color="primary"
					v-if="!isNaN(step) && step > 1 && !$cookies.get('contratoEnviado')"
					@click="step--"
				>Atrás</v-btn>
				<v-btn
					class="rounded-pill my-2"
					color="primary"
					v-else-if="step != 0"
					@click="
					step = 0;
					$cookies.remove('contratoEnviado');
					todoOK = false;
					"
				>Volver al inicio</v-btn>
			</v-col>
		</v-row>

		<v-row>
			<v-col>
				<v-stepper flat v-show="step > 0 && step < 4" :value="step">
					<v-stepper-header>
						<v-stepper-step :complete="step > 1" step="1">Datos personales</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 2" step="2">Datos del suministro</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step :complete="step > 3" step="3">Datos de envío</v-stepper-step>
					</v-stepper-header>
				</v-stepper>

				<v-tabs-items touchless v-model="step">
					<v-tab-item :value="0" key="step0">
						<div class="mx-2">
							<h1 class="text-center font-weigth-bold">¡DATE DE ALTA CON NOSOTROS EN 5 MINUTOS!</h1>
							<h2 class="text-center mt-8">¿Cuál es tu perfil?</h2>
							<div class="d-flex align-center justify-space-around mt-8 flex-wrap" style="gap: 20px">
								<v-card
									class="item-selection align-center pa-4 ma-1"
									style="height: 175px; width: 175px; aspect-ratio: 1"
									@click.stop="step = 1"
								>
									<h4 class="primary--text">Hogar</h4>
									<v-icon large>mdi-home</v-icon>
								</v-card>

								<v-card
									class="item-selection align-center pa-4 ma-1"
									style="height: 175px; width: 175px; aspect-ratio: 1"
									@click.stop="step = 1"
								>
									<h4 class="primary--text">Residencia</h4>
									<v-icon large>mdi-palm-tree</v-icon>
								</v-card>

								<v-card
									class="item-selection align-center pa-4 ma-1"
									style="height: 175px; width: 175px; aspect-ratio: 1"
									@click.stop="step = 'CONTACTO'"
								>
									<h4 class="primary--text">PYME</h4>
									<v-icon large>mdi-domain</v-icon>
								</v-card>
							</div>
						</div>
					</v-tab-item>

					<v-tab-item :value="1" key="step2">
						<v-form ref="form2" class="mx-2">
							<h2 class="text-center">Por favor indíca tus datos personales</h2>
							<!-- <h5 class="text-center">
								Así te podemos calcular una cuota en función de tu características
								y necesidades.
							</h5>-->
							<!-- <div class="d-flex">
								<v-btn
									class="mx-auto my-2"
									color="secondary"
									text
									@click="duplicarEnContrato"
									>COPIAR DATOS DE SUMINISTRO</v-btn
								>
							</div>-->
							<br />
							<v-row>
								<v-col cols="12" sm="4">
									<v-text-field
										hide-details="auto"
										outlined
										label="CIF, NIF, NIE*"
										:rules="[rules.req, rules.ValidateSpanishID]"
										:class="[rounded]"
										v-model="datos.datosTitular.identificador"
									></v-text-field>
								</v-col>
								<template v-if="!esEmpresa">
									<v-col cols="12" sm="4">
										<v-text-field
											hide-details="auto"
											outlined
											label="Nombre*"
											:rules="[(v) => (!esEmpresa ? rules.req(v) : true)]"
											:class="[rounded]"
											v-model="datos.datosTitular.nombre"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											hide-details="auto"
											outlined
											label="Apellido 1*"
											:rules="[(v) => (!esEmpresa ? rules.req(v) : true)]"
											:class="[rounded]"
											v-model="datos.datosTitular.apellido1"
										></v-text-field>
									</v-col>
									<v-col cols="12" sm="4">
										<v-text-field
											hide-details="auto"
											outlined
											label="Apellido 2"
											:class="[rounded]"
											v-model="datos.datosTitular.apellido2"
										></v-text-field>
									</v-col>
								</template>
								<v-col cols="12" sm="4" v-else>
									<v-text-field
										hide-details="auto"
										outlined
										label="Razón Social"
										:rules="[(v) => (esEmpresa ? rules.req(v) : true)]"
										:class="[rounded]"
										v-model="datos.datosTitular.razonSocial"
									/>
								</v-col>
								<v-col cols="12" sm="4">
									<v-text-field
										hide-details="auto"
										outlined
										label="Teléfono *"
										:rules="[rules.req]"
										:class="[rounded]"
										v-model="datos.datosTitular.movil"
									/>
								</v-col>
								<v-col cols="12" sm="4">
									<v-text-field
										hide-details="auto"
										outlined
										label="Email *"
										:rules="[rules.req, rules.email]"
										:class="[rounded]"
										v-model="datos.datosTitular.email"
										persistent-hint
										hint="El email se usará para la firma del contrato"
									/>
								</v-col>
								<v-col cols="12" sm="4">
									<v-menu offset-y :close-on-content-click="false">
										<template v-slot:activator="{ attrs, on }">
											<v-text-field
												outlined
												label="Fecha de Nacimiento"
												v-on="on"
												v-bind="attrs"
												v-model="datos.datosTitular.fnacimientoP"
												readonly
												hide-details="auto"
												:class="[rounded]"
												clearable
											></v-text-field>
										</template>
										<v-date-picker
											no-title
											hide-details="auto"
											outlined
											v-model="datos.datosTitular.fnacimiento"
											@change="
											datos.datosTitular.fnacimientoP = parseDate(
												datos.datosTitular.fnacimiento
											)
											"
										/>
									</v-menu>
								</v-col>
							</v-row>
							<v-subheader class="pb-0 mt-4">Dirección</v-subheader>
							<v-divider class="mt-0 mb-4"></v-divider>
							<v-row>
								<v-col class="col-12 col-sm-6">
									<v-autocomplete
										hide-details="auto"
										hide-no-data
										outlined
										label="Ciudad"
										v-model="datos.datosTitular.ciudad"
										item-text="texto"
										clearable
										cache-items
										:return-object="true"
										:loading="isLoading"
										:items="ciudadesPosibles.datosTitular"
										:rules="[rules.req]"
										:search-input.sync="busqueda.ciudad.datosTitular"
										:class="[rounded]"
										@change="ciudadCambio('datosTitular')"
									></v-autocomplete>
								</v-col>
								<v-col class="col-12 col-sm-6">
									<v-combobox
										v-model="datos.datosTitular.calle"
										hide-no-data
										hide-details="auto"
										outlined
										label="Calle"
										item-text="texto"
										:disabled="!datos.datosTitular.ciudad"
										clearable
										:return-object="true"
										:loading="isLoading"
										:items="callesPosibles.datosTitular"
										:rules="[rules.req]"
										:class="[rounded]"
										@change="calleCambio('datosTitular')"
									/>
								</v-col>
								<div class="col-12 col-sm-6" v-if="typeof datos.datosTitular.calle === 'string'">
									<v-text-field
										hide-details="auto"
										outlined
										:rules="[(v) => alerts.tarifa || rules.req(v), rules.isNumber]"
										label="Código postal"
										:class="[rounded]"
										v-model="datos.datosTitular.codPostal"
									></v-text-field>
								</div>
								<v-col class="col-12 col-sm-4">
									<v-text-field
										hide-details="auto"
										outlined
										:rules="[rules.req, rules.isNumber]"
										label="Número"
										:class="[rounded]"
										v-model="datos.datosTitular.numero"
									></v-text-field>
								</v-col>
								<v-col class="col-12 col-sm-4">
									<v-text-field
										hide-details="auto"
										outlined
										label="Aclarador"
										:class="[rounded]"
										v-model="datos.datosTitular.aclarador"
										:rules="[ rules.req ]"
									></v-text-field>
								</v-col>
							</v-row>
							<template v-if="esEmpresa">
								<v-subheader class="pb-0 mt-4">Datos Representante (opcional)</v-subheader>
								<v-divider class="mt-0"></v-divider>
								<v-row>
									<v-col class="col-12 col-sm-4">
										<v-text-field
											hide-details="auto"
											outlined
											label="Identificador del representante"
											v-model="datos.datosRepresentante.identificador"
											:class="[rounded]"
											hint="CIF, NIF, NIE"
										></v-text-field>
									</v-col>
									<v-col class="col-12 col-sm-4">
										<v-text-field
											hide-details="auto"
											outlined
											label="Nombre del representante"
											:class="[rounded]"
											v-model="datos.datosRepresentante.nombre"
										></v-text-field>
									</v-col>
									<v-col class="col-12 col-sm-4">
										<v-text-field
											hide-details="auto"
											outlined
											label="Email del representante"
											:class="[rounded]"
											v-model="datos.datosRepresentante.email"
										></v-text-field>
									</v-col>
								</v-row>
							</template>
							<div class="mt-4 col-12 text-center">
								<v-btn
									large
									color="primary"
									class="rounded-pill"
									@click="$refs.form2.validate() ? step++ : null"
									type="button"
								>Continuar</v-btn>
							</div>
						</v-form>
					</v-tab-item>

					<v-tab-item :value="2" key="step1">
						<div class="mx-2">
							<h2 class="text-center">Por favor indíca tu dirección y CUPS</h2>
							<h5 class="text-center">
								Así te podemos calcular una cuota en función de tu
								características y necesidades.
							</h5>
							<div class="d-flex">
								<v-btn
									large
									text
									color="secondary"
									class="mx-auto my-2 font-weight-bold"
									@click="duplicarEnSuministro"
								>Copiar datos de titular</v-btn>
							</div>
							<br />
							<v-form ref="form1">
								<div class="row mt-4">
									<div class="col-12 col-sm-6">
										<v-autocomplete
											hide-details="auto"
											hide-no-data
											outlined
											label="Ciudad"
											v-model="datos.suministro.ciudad"
											item-text="texto"
											clearable
											cache-items
											:return-object="true"
											:loading="isLoading"
											:items="ciudadesPosibles.suministro"
											:rules="[(v) => alerts.tarifa || rules.req(v)]"
											:search-input.sync="busqueda.ciudad.suministro"
											@change="ciudadCambio('suministro')"
											:class="[rounded]"
										></v-autocomplete>
									</div>
									<div class="col-12 col-sm-6">
										<v-combobox
											v-model="datos.suministro.calle"
											hide-no-data
											hide-details="auto"
											outlined
											label="Calle"
											item-text="texto"
											:disabled="!datos.suministro.ciudad"
											clearable
											:return-object="true"
											:loading="isLoading"
											:items="callesPosibles.suministro"
											:rules="[(v) => alerts.tarifa || rules.req(v)]"
											:class="[rounded]"
											@change="calleCambio('suministro')"
										/>
									</div>
									<div class="col-12 col-sm-6" v-if="typeof datos.suministro.calle === 'string'">
										<v-text-field
											hide-details="auto"
											outlined
											:rules="[(v) => alerts.tarifa || rules.req(v), rules.isNumber]"
											label="Código postal"
											:class="[rounded]"
											v-model="datos.suministro.codPostal"
										></v-text-field>
									</div>
									<div class="col-12 col-sm-6">
										<v-text-field
											hide-details="auto"
											outlined
											:rules="[(v) => alerts.tarifa || rules.req(v), rules.isNumber]"
											label="Número"
											:class="[rounded]"
											v-model="datos.suministro.numero"
										></v-text-field>
									</div>
									<div class="col-12 col-sm-6">
										<v-text-field
											hide-details="auto"
											outlined
											label="Aclarador"
											:class="[rounded]"
											v-model="datos.suministro.aclarador"
											:rules="[ rules.req ]"
										></v-text-field>
									</div>
								</div>
								<v-divider class="mt-4"></v-divider>
								<v-row class="row mt-4">
									<v-col cols="12" sm="6">
										<v-text-field
											type="text"
											v-model="datos.suministro.cups"
											required
											outlined
											label="CUPS"
											:rules="[rules.cups]"
											hide-details="auto"
											@change="buscarCups"
											:class="[rounded]"
											:loading="isLoading"
										>
											<template v-slot:append>
												<v-scroll-x-reverse-transition>
													<v-icon
														color="success"
														v-if="validCUPS === true && datos.suministro.tarifa == '2.0TD ML'"
													>mdi-check-all</v-icon>
													<v-icon
														color="success"
														v-else-if="validCUPS === true && datos.suministro.tarifa"
													>mdi-check</v-icon>
												</v-scroll-x-reverse-transition>
											</template>
										</v-text-field>
										<v-btn class="my-1" small text color="primary" @click="infoCups = !infoCups">¿Qué es?</v-btn>
										<div class="ms-2 text-justify" v-show="infoCups">
											¿No sabes qué es el CUPS? ¡No pasa nada! ¡Te lo
											explicamos!
											<br />
											Se trata del Código Universal del Punto de Suministro. O
											lo que es lo mismo hablando claro, es como el NIF de la
											instalación y comienza siempre por ES, más otros 20
											dígitos. ¿Lo has encontrado?¡Eureka! ¡Ya queda menos para
											formar parte de la familia {{ nombreEmpresa }} Lovers!
										</div>
									</v-col>
									<v-col class="col-12 col-sm-6">
										<p v-show="datos.suministro.tarifa">Tu tarifa actual es: {{ datos.suministro.tarifa }}</p>
										<span>Nuestro teléfono de contacto es</span>
										<a
											style="white-space: nowrap"
											:href="`tel:${telefono.replaceAll(/ /g, '')}`"
										>{{ telefono }}</a>
										<span>Llámanos.</span>
									</v-col>
									<div style="width: 100%" class="d-flex flex-wrap">
										<v-alert v-show="alerts.tarifa" type="info" text>
											Para la contratación de una tarifa superior a 2.0TD un
											agente se pondrá en contacto contigo
										</v-alert>
										<v-alert v-show="alerts.cups" type="info" text>
											Si no sabes tu CUPS un agente se pondrá en contacto
											contigo
										</v-alert>
									</div>
									<div style="width: 100%" class="d-flex flex-column pa-4">
										<v-checkbox
											hide-details="auto"
											v-model="check.news"
											:label="`¿Quieres recibir información sobre el mundo ${nombreEmpresa} Lovers?`"
										></v-checkbox>
										<v-checkbox
											:rules="[(v) => alerts.tarifa || rules.req(v)]"
											hide-details="auto"
											v-model="check.consent"
											:label="`Consiento el tratamiento de mis datos en el mundo ${nombreEmpresa} Lovers`"
										></v-checkbox>
									</div>
									<div class="mt-4 col-12 text-center">
										<v-btn
											large
											color="primary"
											class="rounded-pill"
											@click="
											$refs.form1.validate()
												? alerts.tarifa || alerts.cups
													? (step = 'CONTACTO')
													: step++
												: null
											"
											type="button"
										>Continuar</v-btn>
									</div>
								</v-row>
							</v-form>
						</div>
					</v-tab-item>

					<v-tab-item :value="3" key="step3">
						<v-form ref="form3" class="mx-2">
							<h2 class="text-center">Por favor indíca los datos de envío</h2>
							<div class="d-flex">
								<v-btn
									large
									class="mx-auto my-2 font-weight-bold"
									color="secondary"
									text
									@click="duplicarEnEnvioYOtros"
								>COPIAR DATOS DE TITULAR</v-btn>
							</div>
							<!-- <h5 class="text-center">
								Así te podemos calcular una cuota en función de tu características
								y necesidades.
							</h5>-->
							<br />
							<v-row>
								<v-col cols="12" sm="8">
									<v-text-field
										hide-details="auto"
										outlined
										label="Nombre Completo*"
										:rules="[rules.req]"
										:class="[rounded]"
										v-model="datos.datosEnvio.nombre"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="col-12 col-sm-6">
									<v-autocomplete
										hide-details="auto"
										hide-no-data
										outlined
										label="Ciudad"
										v-model="datos.datosEnvio.ciudad"
										item-text="texto"
										clearable
										cache-items
										:return-object="true"
										:loading="isLoading"
										:items="ciudadesPosibles.datosEnvio"
										:rules="[rules.req]"
										:search-input.sync="busqueda.ciudad.datosEnvio"
										:class="[rounded]"
										@change="ciudadCambio('datosEnvio')"
									></v-autocomplete>
								</v-col>
								<v-col class="col-12 col-sm-6">
									<v-combobox
										v-model="datos.datosEnvio.calle"
										hide-no-data
										hide-details="auto"
										outlined
										label="Calle"
										item-text="texto"
										:disabled="!datos.datosEnvio.ciudad"
										clearable
										:return-object="true"
										:loading="isLoading"
										:items="callesPosibles.datosEnvio"
										:rules="[rules.req]"
										:class="[rounded]"
										@change="calleCambio('datosEnvio')"
									/>
								</v-col>
								<div class="col-12 col-sm-6" v-if="typeof datos.datosEnvio.calle === 'string'">
									<v-text-field
										hide-details="auto"
										outlined
										:rules="[(v) => alerts.tarifa || rules.req(v), rules.isNumber]"
										label="Código postal"
										:class="[rounded]"
										v-model="datos.datosEnvio.codPostal"
									></v-text-field>
								</div>
								<v-col class="col-12 col-sm-4">
									<v-text-field
										hide-details="auto"
										outlined
										:rules="[rules.req, rules.isNumber]"
										:class="[rounded]"
										label="Número"
										v-model="datos.datosEnvio.numero"
									></v-text-field>
								</v-col>
								<v-col class="col-12 col-sm-4">
									<v-text-field
										hide-details="auto"
										outlined
										label="Aclarador"
										:class="[rounded]"
										v-model="datos.datosEnvio.aclarador"
									></v-text-field>
								</v-col>
							</v-row>
							<div class="mt-4 col-12 text-center">
								<v-btn
									large
									color="primary"
									class="rounded-pill"
									@click="$refs.form3.validate() ? step++ : null"
									type="button"
								>Continuar</v-btn>
							</div>
						</v-form>
					</v-tab-item>

					<v-tab-item :value="4" key="step4">
						<tu-tarifa />
						<v-form v-if="!todoOK" ref="form4">
							<v-row class="my-4">
								<v-col class="col-12 col-sm-6 offset-sm-3 offset-0">
									Por último, dinos tu IBAN
									<v-text-field
										class="mt-4"
										hide-details="auto"
										outlined
										:rules="[rules.req, rules.iban]"
										label="IBAN"
										:class="[rounded]"
										v-model="datos.datosTitular.iban"
									></v-text-field>
								</v-col>
							</v-row>
						</v-form>
						<div class="mt-4 text-center">
							<v-btn
								v-if="todoOK == false"
								color="primary"
								class="rounded-pill"
								@click="$refs.form4.validate() ? subirContrato() : null"
								type="button"
								:loading="subiendoContrato"
							>Únete a la familia {{ nombreEmpresa }}</v-btn>
							<todo-ok v-else-if="todoOK" :tarifa="alerts.tarifa" />
						</div>
						<div style="margin-top: 30px" class="d-flex flex-column justify-content-center text-center">
							<span class="text-final">
								Y, SI PREFIERES, LLÁMANOS ESTÁS A UN PASO DE SER DE LA FAMILIA
								{{ nombreEmpresa.toUpperCase() }}
							</span>
							<a :href="`tel:${telefono.replaceAll(/ /g, '')}`">
								{{
									telefono
								}}
							</a>
						</div>
					</v-tab-item>

					<v-tab-item value="CONTACTO" key="step5">
						<v-form class="mx-2">
							<h2 class="text-center">NUESTRO GESTOR SE VA A ENCARGAR DE ACOMPAÑARTE</h2>
							<h5 class="text-center">Dejanos tus datos de contacto para que te podamos llamar</h5>
							<br />
							<formulario-contacto />
						</v-form>
					</v-tab-item>

				</v-tabs-items>
			</v-col>
		</v-row>

		<v-dialog v-model="error" persistent max-width="600px" width="100%">
			<v-card class="pa-4">
				<div class="d-flex align-center mb-4">
					<v-icon left color="error" x-large>mdi-alert-circle</v-icon>
					<h3 class="error--text">Ha ocurrido un error</h3>
				</div>
				<h4>
					Ha ocurrido un error inesperado, vuelve a intentarlo más tarde o ponte
					en contacto con nosotros
				</h4>
				<div class="d-flex">
					<v-btn text color="primary" :href="`tel:${telefono.replaceAll(/ /g, '')}`">
						<v-icon left>mdi-phone</v-icon>
						{{ telefono }}
					</v-btn>
					<v-btn text color="primary" :href="`mailto:${email}`">
						<v-icon left>mdi-email</v-icon>
						{{ email }}
					</v-btn>
					<v-spacer />
					<v-btn text color="primary" @click.stop="error = false" v-text="'OK'" />
				</div>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import {
	parseDate,
	obtenerNombreTarifaML,
	calcularConsumoAnual,
} from "@/utils/index.js";
import {
	cups,
	req,
	spainIdType,
	ValidateSpanishID,
	email,
	iban,
	isNumber
} from "@/utils/validations.js";

export default {
	components: {
		tuTarifa: () => import("@/components/tuTarifa.vue"),
		TodoOk: () => import("@/components/TodoOk.vue"),
		FormularioContacto: () => import("@/components/FormularioContacto.vue"),
	},
	mounted() {
		this.finishedLoading = true;

		const resizeObserver = new ResizeObserver((entries) => {
			let message = { height: entries[0].target.clientHeight };
			window.top.postMessage(message, "*");
		});

		resizeObserver.observe(this.$refs.mainContainer);

		this.datos.suministro = this.$cookies.isKey("progresoSuministro")
			? this.$cookies.get("progresoSuministro")
			: this.datos.suministro;
		this.datos.datosTitular = this.$cookies.isKey("progresoTitular")
			? this.$cookies.get("progresoTitular")
			: this.datos.datosTitular;
		this.datos.datosRepresentante = this.$cookies.isKey(
			"progresoRepresentantes"
		)
			? this.$cookies.get("progresoRepresentantes")
			: this.datos.datosRepresentante;
		this.datos.datosEnvio = this.$cookies.isKey("progresoEnvio")
			? this.$cookies.get("progresoEnvio")
			: this.datos.datosEnvio;

		this.ciudadesPosibles = {
			suministro: this.datos.suministro.ciudad
				? [this.datos.suministro.ciudad]
				: [],
			datosTitular: this.datos.datosTitular.ciudad
				? [this.datos.datosTitular.ciudad]
				: [],
			datosEnvio: this.datos.datosEnvio.ciudad
				? [this.datos.datosEnvio.ciudad]
				: [],
		};

		this.todoOK = this.$cookies.get("contratoEnviado") ? true : false
	},
	props: {
		empresa: String,
		nombreEmpresa: String,
		telefono: String,
		email: String,
	},
	data() {
		let step = 0;
		if (this.$cookies.get("contratoEnviado")) step = 4
		if ( this.$route.query.tipo )
			step = 1
		if (this.$route.query.tipo && this.$route.query.tipo == 'pyme')
			step = 'CONTACTO'
		return {
			rounded:
				this.$route.query.rounded && JSON.parse(this.$route.query.rounded)
					? "rounded-pill"
					: "",
			finishedLoading: false,
			nombreAgente: "FormularioWeb",
			step: step,
			facturaEnMano: false,
			infoCups: false,
			error: false,
			check: [null, null],
			datos: {
				suministro: {
					ciudad: null,
					tarifa: null,
				},
				datosTitular: {
					ciudad: null,
					fnacimiento: "",
					fnacimientoP: "",
				},
				datosEnvio: {
					ciudad: null,
					calle: null,
				},
				datosRepresentante: {},
			},
			ciudadesPosibles: {
				suministro: [],
				datosTitular: [],
				datosEnvio: [],
			},
			callesPosibles: {
				suministro: [],
				datosTitular: [],
				datosEnvio: [],
			},
			debouncer: {
				suministro: null,
				datosTitular: null,
			},

			idEmpresa: 1,
			idUsuario: 1,

			rules: { req, ValidateSpanishID, cups: this.cups, email, iban, isNumber },

			enviadoEfor: false,

			isLoading: false,
			busqueda: {
				ciudad: {
					suminsitro: "",
					datosTitular: "",
				},
			},
			todoOK: false,
			subiendoContrato: false,
		};
	},
	computed: {
		esEmpresa() {
			return (
				this.datos.datosTitular.identificador != null &&
				spainIdType(this.datos.datosTitular.identificador) == "cif"
			);
		},
		alerts() {
			return {
				tarifa: this.datos.suministro.cups
					? this.datos.suministro.tarifa != "2.0TD ML"
					: false,
				cups: !this.datos.suministro.cups,
			};
		},
		validCUPS() {
			return cups(this.datos.suministro.cups)
		}
	},
	methods: {
		parseDate,
		cups(v) {
			const validation = cups(v)
			if (validation === true) {
				this.buscarCups(v)
				return true;
			} else return validation;
		},
		async subirContrato() {
			try {
				this.subiendoContrato = true;
				const forms = [
					{ form: 1, step: 1 },
					{ form: 2, step: 1 },
					{ form: 3, step: 2 },
					{ form: 4, step: 3 },
				];
				for (const f of forms) {
					if (!this.$refs[`form${f.form}`].validate()) {
						this.step = f.step;
						this.subiendoContrato = false;
						return;
					}
				}
				if (
					this.datos.suministro.ciudad == null &&
					this.datos.suministro.cups != null
				)
					this.datos.suministro.ciudad = {};
				if (
					this.datos.suministro.calle == null &&
					this.datos.suministro.cups != null
				)
					this.datos.suministro.calle = {};

				const tokens = await (
					await fetch(
						"https://pruebas.alumbraenergia.es/api/public/autologin.php"
					)
				).json();

				const { IdUsuario, Nombre } = JSON.parse(
					JSON.parse(atob(tokens.datosEmpresa.split(".")[1])).iss
				);
				const IdEmpresa = 1;

				let fechaAlta = new Date();
				fechaAlta.setDate(fechaAlta.getDate() + 4);
				let fechaVto = new Date(fechaAlta);
				fechaVto.setFullYear(fechaVto.getFullYear() + 1);

				fechaAlta = parseDate(fechaAlta, false, false).replaceAll(/ /g, "/");
				fechaVto = parseDate(fechaVto, false, false).replaceAll(/ /g, "/");

				let contratoPotencial = {
					IdEmpresa: IdEmpresa,
					IdUsuario: IdUsuario,
					idUsuarioBorrador: JSON.parse(atob(tokens.token.split(".")[1]))
						.idUsuario,
					entorno: "Luz",
					enviarSMS: false,
					enviarEmail: true,
					residencial: true,
					empresa: JSON.parse(atob(tokens.token.split(".")[1])).empresa,
					ContratosPotenciales: [
						{
							ActivacionPrevista: "",
							FechaEfecto: 7,
							CodigoCUPS: this.datos.suministro.cups,
							CodigoREEDistribuidora: this.datos.suministro.codDistribuidora,
							CallePuntoSuministro:
								typeof this.datos.suministro.calle != "string" &&
									this.datos.suministro.calle != null
									? this.datos.suministro.calle.desCalle
									: this.datos.suministro.calle,
							Numero: this.datos.suministro.numero,
							Aclarador: this.datos.suministro.aclarador,
							CodPostal: this.datos.suministro.codPostal,
							PaisSuministro: this.datos.suministro.ciudad.desPais,
							InePaisSuministro: this.datos.suministro.ciudad.codPais,
							MunicipioSuministro: this.datos.suministro.ciudad.desCiudad,
							IneMunicipioSuministro: this.datos.suministro.ciudad.codMunicipio,
							CiudadSuministro: this.datos.suministro.ciudad.desCiudad,
							IneCiudadSuministro: this.datos.suministro.ciudad.codCiudad,
							ProvinciaSuministro: this.datos.suministro.ciudad.desProvincia,
							IneProvinciaSuministro: this.datos.suministro.ciudad.codProvincia,
							CodigoProveedorProvinciaSuministro:
								this.datos.suministro.ciudad.codigoProveedorProvincia,
							CodigoProveedorMunicipioSuministro:
								this.datos.suministro.ciudad.codigoProveedorMunicipio,
							CodigoProveedorCiudadSuministro:
								this.datos.suministro.ciudad.codigoProveedorCiudad,
							Identificador: this.datos.datosTitular.identificador,
							NombreTitular: this.datos.datosTitular.nombre,
							Apellido1Titular: this.datos.datosTitular.apellido1,
							Apellido2Titular: this.datos.datosTitular.apellido2,
							RazonSocialTitular: this.datos.datosTitular.razonSocial,
							CPTitular: this.datos.datosTitular.codPostal,
							PaisTitular: this.datos.datosTitular.ciudad.desPais,
							InePaisTitular: this.datos.datosTitular.ciudad.codPais,
							MunicipioTitular: this.datos.datosTitular.ciudad.desCiudad,
							IneMunicipioTitular: this.datos.datosTitular.ciudad.codMunicipio,
							CiudadTitular: this.datos.datosTitular.ciudad.desCiudad,
							IneCiudadTitular: this.datos.datosTitular.ciudad.codCiudad,
							CalleTitular:
								typeof this.datos.datosTitular.calle != "string" &&
									this.datos.datosTitular.calle != null
									? this.datos.datosTitular.calle.desCalle
									: this.datos.datosTitular.calle,
							NumeroTitular: this.datos.datosTitular.numero,
							AclaradorTitular: this.datos.datosTitular.aclarador,
							ProvinciaTitular: this.datos.datosTitular.ciudad.desProvincia,
							IneProvinciaTitular: this.datos.datosTitular.ciudad.codProvincia,
							CodigoProveedorProvinciaTitular:
								this.datos.datosTitular.ciudad.codigoProveedorProvincia,
							CodigoProveedorMunicipioTitular:
								this.datos.datosTitular.ciudad.codigoProveedorMunicipio,
							CodigoProveedorCiudadTitular:
								this.datos.datosTitular.ciudad.codigoProveedorCiudad,
							Movil: this.datos.datosTitular.movil,
							EmailTitular: this.datos.datosTitular.email,
							IsDestinoEnergia: false, //? DEPENDE DE QUE UN CAMPO LLAMADO DOMESTICO SEA 'true', SI NO SIEMPRE FALSE
							NombreEnvio: this.datos.datosEnvio.nombre,
							PaisEnvio: this.datos.datosEnvio.ciudad.desPais,
							InePaisEnvio: this.datos.datosEnvio.ciudad.codPais,
							MunicipioEnvio: this.datos.datosEnvio.ciudad.desCiudad,
							IneMunicipioEnvio: this.datos.datosEnvio.ciudad.codMunicipio,
							CPEnvio: this.datos.datosEnvio.codPostal,
							CiudadEnvio: this.datos.datosEnvio.ciudad.desCiudad,
							IneCiudadEnvio: this.datos.datosEnvio.ciudad.codCiudad,
							CalleEnvio:
								typeof this.datos.datosEnvio.calle != "string" &&
									this.datos.datosEnvio.calle != null
									? this.datos.datosEnvio.calle.desCalle
									: this.datos.datosEnvio.calle,
							NumeroEnvio: this.datos.datosEnvio.numero,
							AclaradorEnvio: this.datos.datosEnvio.aclarador,
							ProvinciaEnvio: this.datos.datosEnvio.ciudad.desProvincia,
							IneProvinciaEnvio: this.datos.datosEnvio.ciudad.codProvincia,
							CodigoProveedorProvinciaEnvio:
								this.datos.datosEnvio.ciudad.codigoProveedorProvincia,
							CodigoProveedorMunicipioEnvio:
								this.datos.datosEnvio.ciudad.codigoProveedorMunicipio,
							CodigoProveedorCiudadEnvio:
								this.datos.datosEnvio.ciudad.codigoProveedorCiudad,
							NombrePagador: [
								this.datos.datosTitular.nombre,
								this.datos.datosTitular.apellido1,
								this.datos.datosTitular.apellido2,
							]
								.filter((n) => n != null)
								.join(" "),
							IdentificadorPagador: this.datos.datosTitular.identificador,
							TextoTipoCobro: "DOMICILIADO",
							NombreAgente: Nombre,
							TextoViaSuministro:
								this.datos.suministro.calle.tipoCalle || "CALLE",
							TextoViaTitular:
								this.datos.datosTitular.calle.tipoCalle || "CALLE",
							TextoViaEnvio: this.datos.datosEnvio.calle.tipoCalle || "CALLE",

							P1: this.datos.suministro.p1,
							P2: this.datos.suministro.p2,
							P3: this.datos.suministro.p3,
							P4: this.datos.suministro.p4,
							P5: this.datos.suministro.p5,
							P6: this.datos.suministro.p6,
							ConsumoEstimadoP1: this.datos.suministro.ConsumoEstimadoP1,
							ConsumoEstimadoP2: this.datos.suministro.ConsumoEstimadoP2,
							ConsumoEstimadoP3: this.datos.suministro.ConsumoEstimadoP3,
							ConsumoEstimadoP4: this.datos.suministro.ConsumoEstimadoP4,
							ConsumoEstimadoP5: this.datos.suministro.ConsumoEstimadoP5,
							ConsumoEstimadoP6: this.datos.suministro.ConsumoEstimadoP6,
							ConsumoEstimado: Math.round(
								this.datos.suministro.consumoAnualSips
							),
							Tarifa: this.datos.suministro.tarifa,
							TextoTarifaAcceso: this.datos.suministro.tarifa.replaceAll(
								/ ML/g,
								""
							),
							TarifaGrupo: "PRODUCTO WEB",
							TextoModeloContrato: "Contrato Alumbra",
							TextoTipoImpresion: "E",
							IBAN: this.datos.datosTitular.iban,
							TextoPeriodo: "MENSUAL",
							TipoSolicitud: "CZ",
							Producto: "Gastos Gestion",
							PerfilFacturacionContratoTarifa: "Alumbra BT Variable ML",

							IdModeloContrato: 16,
							IdIdiomaInforme: 1,

							Booleanos: {
								IsFirmaDigital: false,
								IsElectronico: false,
							},
							FechaAlta: fechaAlta,
							FechaVto: fechaVto,
						},
					],
				};

				const { data: res } = await axios({
					method: "POST",
					url: `https://api.canales.lowcostpower.es/api/v1/ContratoPotencial/Verificar/`,
					data: contratoPotencial,
					headers: {
						Authorization: tokens.tokenS,
					},
				});

				if (res.Contratos[0].Incidencias.length > 0) {
					this.subiendoContrato = false;
					throw res.Contratos[0].Incidencias;
					return;
				}

				// const { data: idBorrador } = await axios({
				// 	method: "POST",
				// 	url: `https://pruebas.alumbraenergia.es/api/public/contratos.php`,
				// 	data: {
				// 		...contratoPotencial,
				// 		estado: this.alerts.tarifa ? "Borrador" : "Enviado a verificación",
				// 		token: tokens.token,
				// 	},
				// });

				this.$cookies.set("contratoEnviado", true, "7d");

				if (this.alerts.tarifa) {
					this.todoOK = true;
					this.subiendoContrato = false;
					return;
				}

				const { data: contrato } = await axios({
					method: "POST",
					url: `https://api.canales.lowcostpower.es/api/v1/ContratoPotencial/POST/`,
					data: contratoPotencial,
					headers: {
						Authorization: tokens.tokenS,
					},
				});

				if (contrato.Contratos[0].Incidencias.length > 0) {
					this.subiendoContrato = false;
					throw contrato.Contratos[0].Incidencias;
					return;
				}

				try {
					let { data: email_firma } = await axios({
						method: "GET",
						url: `https://pruebas.alumbraenergia.es/api/public/enviar_firma_email.php`,
						params: {
							codigoContrato: contrato.Contratos[0].CodigoContrato,
							empresa: this.empresa,
							idContrato: contrato.Contratos[0].CodigoContrato,
							token: tokens.token,
							IdAgente: 31
						},
					});
				} catch (e) {
					console.error(e)
					this.$root.$emit("snack", "No se ha podido enviar el email de firma");
					this.subiendoContrato = false;
					throw "No se ha podido enviar el email de firma";
					return;
				}

				try {
					await axios({
						method: "POST",
						url: `https://pruebas.alumbraenergia.es/api/public/contactoEfor.php`,
						data: {
							token: tokens.token,
							contact: {
								email: this.datos.datosTitular.email,
								phone: this.datos.datosTitular.movil,
								firstName: this.datos.datosTitular.nombre || this.datos.datosTitular.razonSocial,
								lastName: [this.datos.datosTitular.apellido1, this.datos.datosTitular.apellido2].filter(d => !!d).join(' '),
							},
							tagId: 4
						},
					});
				} catch (e) {
					console.log(e)
					this.subiendoContrato = false;
					throw "false";
					return;
				}

				this.todoOK = true;
				this.subiendoContrato = false;
			} catch (e) {
				console.error(e)
				this.subiendoContrato = false;
				this.error = true;
				return;
			}

			// alert("todo OK");
		},
		buscarCiudad(filtro, target = "suministro") {
			return new Promise((resolve) => {
				clearTimeout(this.debouncer[target]);
				this.debouncer[target] = setTimeout(() => {
					axios({
						url: "https://apicallejero.portalswitching.com/api/v1/Ciudad/GetDTOALLbyTexto",
						method: "POST",
						data: [
							{
								FiltroTexto: filtro
									.normalize("NFD")
									.replace(/[\u0300-\u036f]/g, ""),
							},
						],
					}).then((res) => {
						this.ciudadesPosibles[target] = res.data.map((item) => {
							item.texto = `${item.desCiudad} (${item.desProvincia})`;
							return item;
						});
						this.isLoading = false;
						resolve();
					});
				}, 500);
			});
		},
		buscarCalle(target = "suministro") {
			return new Promise((resolve) => {
				let { codMunicipio, codProvincia } = this.datos[target].ciudad;
				axios({
					url: `https://apicallejero.portalswitching.com/api/v1/Calle/GetDTOALLCalles/CodigoMunicipio/${codMunicipio}/CodProvincia/${codProvincia}`,
					method: "GET",
				}).then((res) => {
					let temp = res.data.map((item) => {
						item.texto = `${item.desCalle} (${item.codigoPostal})`;
						return item;
					});

					this.callesPosibles[target] = temp;
					this.isLoading = false;
					resolve();
				});
			});
		},
		ciudadCambio(target = "suministro") {
			console.log(this.datos[target].ciudad);
			if (this.datos[target].ciudad == null) {
				this.datos[target].calle = null;
				return;
			}
			this.datos[target].provincia = this.datos[target].ciudad.desProvincia;

			this.isLoading = true;
			this.buscarCalle(target);

			this.ciudadesPosibles[target] = [this.datos[target].ciudad];

			this.ciudadFound = true;
		},
		calleCambio(target = "suministro") {
			if (this.datos[target].calle == null) return;

			this.datos[target].codPostal = this.datos[target].calle.codigoPostal;
		},
		duplicarEnEnvioYOtros() {
			this.datos.datosEnvio.ciudad = this.datos.datosTitular.ciudad;
			this.ciudadesPosibles.datosEnvio = [this.datos.datosEnvio.ciudad];
			this.datos.datosEnvio.calle = this.datos.datosTitular.calle;
			this.callesPosibles.datosEnvio = [this.datos.datosEnvio.calle];

			this.datos.datosEnvio.calle = this.datos.datosTitular.calle;
			this.datos.datosEnvio.numero = this.datos.datosTitular.numero;
			this.datos.datosEnvio.aclarador = this.datos.datosTitular.aclarador;
			this.datos.datosEnvio.codPostal = this.datos.datosTitular.codPostal;

			if (!this.esEmpresa) {
				let { nombre, apellido1, apellido2 } = this.datos.datosTitular;
				var nombreCompleto = [nombre, apellido1, apellido2]
					.filter((item) => !!item)
					.join(" ");
			} else {
				var nombreCompleto = this.datos.datosTitular.razonSocial;
			}

			this.datos.datosEnvio.nombre = nombreCompleto;
			// this.datos.datosEnvio.identificador = this.datos.datosBancarios.identificador;
		},
		duplicarEnSuministro() {
			this.datos.suministro.ciudad = this.datos.datosTitular.ciudad;
			this.ciudadesPosibles.suministro = [this.datos.datosTitular.ciudad];
			this.datos.suministro.calle = this.datos.datosTitular.calle;
			this.callesPosibles.suministro = [this.datos.datosTitular.calle];

			this.datos.suministro.provincia = this.datos.datosTitular.provincia;
			this.datos.suministro.numero = this.datos.datosTitular.numero;
			this.datos.suministro.codPostal = this.datos.datosTitular.codPostal;
			this.datos.suministro.aclarador = this.datos.datosTitular.aclarador;
		},
		duplicarEnContrato() {
			this.datos.datosTitular.ciudad = this.datos.suministro.ciudad;
			this.ciudadesPosibles.datosTitular = [this.datos.datosTitular.ciudad];
			this.datos.datosTitular.calle = this.datos.suministro.calle;
			this.callesPosibles.datosTitular = [this.datos.datosTitular.calle];

			this.datos.datosTitular.provincia = this.datos.suministro.provincia;
			this.datos.datosTitular.numero = this.datos.suministro.numero;
			this.datos.datosTitular.codPostal = this.datos.suministro.codPostal;
			this.datos.datosTitular.aclarador = this.datos.suministro.aclarador;
		},
		buscarCups() {
			if (
				this.datos.suministro.cups == null ||
				this.datos.suministro.cups == ""
			) {
				this.datos.suministro.tarifa = null;
				return;
			}
			axios({
				url: "https://sips.sigeenergia.com:61843/SIPSAPIvLast/api/v2/ClientesSips/GetClientesPost",
				method: "POST",
				data: {
					CodigoCUPS: this.datos.suministro.cups,
					NombreEmpresaDistribuidora: "",
					MunicipioPS: "",
					CodigoProvinciaPS: "",
					CodigoPostalPS: this.cp,
					CodigoTarifaATREnVigor: "",
					ListCUPS: "",
					LoadAllDatosCliente: true,
					LoadConsumos: true,
					IsExist: true,
				},
			}).then(async (res) => {
				this.isLoading = true;
				if (res.data.ClientesSips.length == 0) {
					this.isLoading = false;
					return;
				}
				if (
					"004" == res.data.ClientesSips[0].CodigoTarifaATREnVigor ||
					"006" == res.data.ClientesSips[0].CodigoTarifaATREnVigor
				) {
					res.data.ClientesSips[0].PotenciasContratadasEnWP3 =
						res.data.ClientesSips[0].PotenciasContratadasEnWP1;
					res.data.ClientesSips[0].PotenciasContratadasEnWP2 = null;
				}

				let i = this.datos.suministro.cups.substring(2, 8);
				let dist;
				"003110" == i
					? (dist = "0023")
					: "003130" == i
						? (dist = "0029")
						: "003144" == i
							? (dist = "0396")
							: "003150" == i
								? (dist = "0288")
								: "003160" == i
									? (dist = "0363")
									: "003140" == i
										? res.data.ClientesSips.length > 0 &&
											res.data.ClientesSips[0].CodigoPostalPS.startsWith("50")
											? (dist = "0029")
											: (dist = "0024")
										: (dist = this.datos.suministro.cups.substring(2, 6));

				let aux = res.data.ClientesSips[0];

				let tarifaBOE = obtenerNombreTarifaML(
					aux.CodigoTarifaATREnVigor
				).actual;

				this.datos.suministro.tarifa = tarifaBOE;

				if (tarifaBOE != "2.0TD ML") {
					this.isLoading = false;
					return;
				}

				if (tarifaBOE == "2.0TD ML") {
					if (
						aux.PotenciasContratadasEnWP1 == null ||
						aux.PotenciasContratadasEnWP1 == 0
					) {
						if (
							aux.PotenciasContratadasEnWP2 != null ||
							aux.PotenciasContratadasEnWP2 != 0
						) {
							aux.PotenciasContratadasEnWP1 = aux.PotenciasContratadasEnWP2;
							aux.PotenciasContratadasEnWP2 = 0;
							if (
								aux.PotenciasContratadasEnWP3 == null ||
								aux.PotenciasContratadasEnWP3 == 0
							) {
								aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP1;
							}
						}
					} else if (
						aux.PotenciasContratadasEnWP3 == null ||
						aux.PotenciasContratadasEnWP3 == 0
					) {
						aux.PotenciasContratadasEnWP3 = aux.PotenciasContratadasEnWP1;
					}
					aux.PotenciasContratadasEnWP2 = 0;
					aux.PotenciasContratadasEnWP4 = null;
					aux.PotenciasContratadasEnWP5 = null;
					aux.PotenciasContratadasEnWP6 = null;
				}
				res.data.ClientesSips[i] = aux;

				this.datos.suministro.codDistribuidora = dist;
				this.datos.suministro.sugerenciaCiudad = `${aux.DesMunicipioPS} (${aux.DesProvinciaPS})`;

				if (res.data.DatosTitular && res.data.DatosTitular.length > 0) {
					this.datos.suministro.sugerenciaCalle =
						res.data.DatosTitular[0].DireccionSuministro;
				}
				// this.datos.suministro.codPostal = aux.CodigoPostalPS;
				this.datos.suministro.p1 = aux.PotenciasContratadasEnWP1;
				this.datos.suministro.p2 = aux.PotenciasContratadasEnWP2;
				this.datos.suministro.p3 = aux.PotenciasContratadasEnWP3;
				this.datos.suministro.p4 = aux.PotenciasContratadasEnWP4;
				this.datos.suministro.p5 = aux.PotenciasContratadasEnWP5;
				this.datos.suministro.p6 = aux.PotenciasContratadasEnWP6;

				let consumosEstimados = calcularConsumoAnual(
					res.data.ConsumosSips,
					this.datos.suministro.cups,
					res.data.ClientesSips[0].CodigoTarifaATREnVigor
				);

				this.datos.suministro.ConsumoEstimadoP1 = consumosEstimados.a1;
				this.datos.suministro.ConsumoEstimadoP2 = consumosEstimados.a2;
				this.datos.suministro.ConsumoEstimadoP3 = consumosEstimados.a3;
				this.datos.suministro.ConsumoEstimadoP4 = consumosEstimados.a4;
				this.datos.suministro.ConsumoEstimadoP5 = consumosEstimados.a5;
				this.datos.suministro.ConsumoEstimadoP6 = consumosEstimados.a6;

				this.datos.suministro.consumoAnualSips = consumosEstimados.total;

				// this.busqueda.ciudad.suministro = res.data.ClientesSips[0].DesMunicipioPS;

				// await this.buscarCiudad(this.busqueda.ciudad.suministro);

				// this.datos.suministro.ciudad =
				// this.ciudadesPosibles.suministro.find(
				// 			(ciudad) =>
				// 				ciudad.desCiudad == this.busqueda.ciudad.suministro
				// 		);

				this.isLoading = false;
			});
		},
	},
	watch: {
		"busqueda.ciudad.suministro"(val) {
			if (val === "" || val === null || val.length <= 2) {
				this.ciudadesPosibles.suministro = [];
				this.callesPosibles.suministro = [];
				this.ciudadFound = false;
				return;
			}
			if (this.ciudadFound) return;
			this.buscarCiudad(val);
			if (this.isLoading == true) return;
			this.isLoading = true;
		},
		"busqueda.ciudad.datosTitular"(val) {
			if (val === "" || val === null || val.length <= 2) {
				this.ciudadesPosibles.datosTitular = [];
				this.callesPosibles.datosTitular = [];
				this.ciudadFound = false;
				return;
			}
			if (this.ciudadFound) return;
			this.buscarCiudad(val, "datosTitular");
			if (this.isLoading == true) return;
			this.isLoading = true;
		},
		"busqueda.ciudad.datosEnvio"(val) {
			if (val === "" || val === null || val.length <= 2) {
				this.ciudadesPosibles.datosEnvio = [];
				this.callesPosibles.datosEnvio = [];
				this.ciudadFound = false;
				return;
			}
			if (this.ciudadFound) return;
			this.buscarCiudad(val, "datosEnvio");
			if (this.isLoading == true) return;
			this.isLoading = true;
		},
		async step(val, oldVal) {
			switch (oldVal) {
				case 2:
					this.$cookies.set("progresoSuministro", this.datos.suministro, "7d");
					return;
				case 1:
					this.$cookies.set(
						"progresoTitular",
						{ ...this.datos.datosTitular, iban: null },
						"7d"
					);
					this.$cookies.set(
						"progresoRepresentantes",
						this.datos.datosRepresentante,
						"7d"
					);
					if (!this.enviadoEfor) {

						const tokens = await (
							await fetch(
								"https://pruebas.alumbraenergia.es/api/public/autologin.php"
							)
						).json();

						try {
							await axios({
								method: "POST",
								url: `https://pruebas.alumbraenergia.es/api/public/contactoEfor.php`,
								data: {
									token: tokens.token,
									contact: {
										email: this.datos.datosTitular.email,
										phone: this.datos.datosTitular.movil,
										firstName: this.datos.datosTitular.nombre || this.datos.datosTitular.razonSocial,
										lastName: [this.datos.datosTitular.apellido1, this.datos.datosTitular.apellido2].filter(d => !!d).join(' '),
									},
									tagId: 4
								},
							});
						} catch (e) {
							console.log(e)
							return;
						}

						this.enviadoEfor = true;

					}
					return;
				case 3:
					this.$cookies.set("progresoEnvio", this.datos.datosEnvio, "7d");
					return;
			}
		},
	},
};
</script>


<style lang="scss">
h1,
h2,
h3 {
	color: var(--v-primary-base);
	font-weight: bold;
	font-size: calc(1.325rem + 0.9vw);
	font-family: "silka_bold";
}
h5,
.h5 {
	font-size: 1.25rem;
	font-weight: 500;
}
a.nav-link,
a {
	padding: 0.5rem 0.5rem !important;
	text-decoration: none !important;
	font-weight: bold;
	position: relative;
}

.text-final {
	color: var(--v-primary-base);
	font-weight: bold;
	font-size: 1.5rem;
	font-family: "silka_bold" !important;
	& + a {
		font-weight: bold;
		font-size: 1.5rem;
		text-decoration: none;
		color: var(--v-secondary-base) !important;
		font-family: "silka_bold" !important;
	}
}

.item-selection {
	display: grid !important;
	grid-template-rows: 1fr 0fr;
	text-align: center;
}

.theme--light.v-tabs-items {
	background-color: transparent !important;
}
</style>